@import './custom-variables';
@import '../node_modules/prismjs/themes/prism-tomorrow';
@import '@assets/fonts/feather/iconfont.css';
@import '@styles/core.scss';
@import './assets/scss/style.scss';

.col-hidden {
  display: none;
}

$object-fits: contain, cover, fill, none, scale-down;
@each $value in $object-fits {
  .object-#{$value} {
    object-fit: $value;
  }
}

.button-group {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 10px;
}

.nav-pills {
  .nav-link {
    background-color: #e7e8e9;
    margin-right: 16px;
    margin-bottom: 16px;
  }
}

.mt {
  &-11 {
    margin-top: 11px;
  }
  &-16 {
    margin-top: 16px;
  }
  &-24 {
    margin-top: 24px;
  }
  &-32 {
    margin-top: 32px;
  }
}

.mb {
  &-11 {
    margin-bottom: 11px;
  }
  &-16 {
    margin-bottom: 16px;
  }
  &-32 {
    margin-bottom: 32px;
  }
}

.gap {
  &-4 {
    gap: 4px;
  }
  &-8 {
    gap: 8px;
  }
  &-10 {
    gap: 10px;
  }
  &-20 {
    gap: 20px;
  }
}
.rounded {
  &-full {
    border-radius: 50%;
  }
  &-6 {
    border-radius: 6px;
  }
}
.bg {
  &-white {
    background-color: white;
  }
  &-black50 {
    background-color: $black-50;
  }
  &-black150 {
    background-color: $black-150;
  }
  &-black450 {
    background-color: $black-450;
  }
}

.border {
  &-1 {
    border-width: 1px;
  }
  &-white {
    border-color: white;
  }
}

.border-b {
  &-1 {
    border-bottom-width: 1px;
  }
  &-white {
    border-color: white;
  }
}

.text {
  &-uppercase {
    text-transform: uppercase;
  }
  &-white {
    color: white;
  }
  &-black300 {
    color: $black-300;
  }
  &-black400 {
    color: $black-400;
  }
  &-black500 {
    color: $black-500;
  }
  &-red500 {
    color: $red-500;
  }
  &-blue500 {
    color: $blue-500;
  }
  &-green500 {
    color: $green-500;
  }

  &-orange500 {
    color: $orange-500;
  }
}

.text {
  &-7-8-400 {
    font-size: 7px;
    line-height: 8px;
    font-weight: 400;
  }
  &-7-8-700 {
    font-size: 7px;
    line-height: 8px;
    font-weight: 700;
  }
  &-7_5-9-400 {
    font-size: 7.5px;
    line-height: 9px;
    font-weight: 400;
  }
  &-8-9-400 {
    font-size: 8px;
    line-height: 9px;
    font-weight: 400;
  }
  &-10-11-700 {
    font-size: 10px;
    line-height: 11px;
    font-weight: 700;
  }
  &-12-15-600 {
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
  }
  &-14-21-400 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }
  &-14-21-700 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
  }
  &-14-22-500 {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }
  &-15-24-400 {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
  }
  &-15-24-500 {
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
  }
  &-15-24-600 {
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
  }
  &-15-24-700 {
    font-size: 15px;
    line-height: 24px;
    font-weight: 700;
  }
  &-16-21-700 {
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;
  }
  &-18-22-500 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
  }
  &-24-28-700 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
  }
}
.black {
  &-400 {
    color: $black-400;
  }
  &-500 {
    color: $black-500;
  }
  &-550 {
    color: $black-550;
  }
}
.min-h {
  &-200 {
    width: 200px;
  }
}

.h {
  &-screen {
    height: 100vh;
  }
  &-full {
    height: 100%;
  }
  &-inherit {
    height: inherit;
  }
  &-46 {
    height: 46px;
  }
}

.flex {
  &-1 {
    flex: 1;
  }
}
.w {
  &-fit {
    width: fit-content;
  }
  &-full {
    width: 100%;
  }
  &-max-content {
    width: max-content;
  }
  &-46 {
    width: 46px;
  }
  &-500 {
    width: 500px;
  }
  &-50c {
    width: 50%;
  }
  &-80c {
    width: 80%;
  }
}

.px {
  &-16 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.p {
  &-12 {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.pt {
  &-16 {
    padding-top: 16px;
  }
  &-20 {
    padding-top: 20px;
  }
}

.pl {
  &-16 {
    padding-left: 16px;
  }
  &-20 {
    padding-left: 20px;
  }
}

.pr {
  &-16 {
    padding-right: 16px;
  }
  &-20 {
    padding-right: 20px;
  }
  &-24 {
    padding-right: 24px;
  }
}

.pb {
  &-16 {
    padding-bottom: 16px;
  }
  &-20 {
    padding-bottom: 20px;
  }
  &-24 {
    padding-bottom: 24px;
  }
}

.py {
  &-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &-22 {
    padding-top: 22px;
    padding-bottom: 22px;
  }
}
.overflow {
  &-scroll {
    overflow: scroll;
  }
  &-auto {
    overflow: auto;
  }
}

.top {
  &-12 {
    top: 12px;
  }
}

.right {
  &-12 {
    right: 12px;
  }
}

.z {
  &-999 {
    z-index: 999;
  }
}

.max-w {
  &-half {
    max-width: 50%;
  }
  &-75per {
    max-width: 75%;
  }
}
