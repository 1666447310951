//USE THIS FILE TO OVERRIDE CSS VARIABLES

$primary: #215bc1;

$black-50: #f8f8f8;
$black-100: #f3f2f7;
$black-100: #f3f2f7;
$black-150: #e4e4e4;
$black-300: #82868b;
$black-400: #6e6b7b;
$black-450: #5e5873;
$black-500: #343434;
$black-550: #4b4b4b;

$red-500: #ea5455;

$orange-500: #ff9f43;

$blue-500: #2373ff;

$green-500: #28c76f;

:root {
  --primary: #215bc1;
  --black-50: #f8f8f8;
  --black-100: #f3f2f7;
  --black-300: #82868b;
  --black-400: #6e6b7b;
  --black-450: #5e5873;
  --black-500: #343434;
  --black-550: #4b4b4b;

  --red-500: #ea5455;
  --blue-500: #2373ff;

  --green-500: #28c76f;
}
